import React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Root from '../components/Root'
import Meta from '../components/Meta'
import { SimpleHero } from '../components/Heroes'

const Error = ({ location }) => {
    return (
        <Root {...{ location }}>
            <Meta />
            <SimpleHero
                simpleHeroPrimary={{
                    heading: 'We cannot find that',
                    description: `The page you're looking for doesn't exist. Please use the menu above or contact us directly for more information.`,
                }}
            />
        </Root>
    )
}

export default withPrismicUnpublishedPreview(Error)
